import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    NgZone,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from "@angular/core";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Item } from "../../pojo/Item";
import { ColorBucketType, ItemColorMappingService } from "../../services/item.color.mapping.service";
import {
    AvailCompType,
    AvailData,
    AvailDataService,
    AvailItem,
    AvailMode,
    AvailRow,
    AvailService,
    EventPerm,
} from "../../services/avail.service";
import { OptBean, OptUtilizationView } from "../s25-opt/s25.opt.component";
import { OptUtil } from "../s25-opt/s25.opt.util";
import { UserprefService } from "../../services/userpref.service";
import { Flavor } from "../../pojo/Util";
import { S25Const } from "../../util/s25-const";
import { Debounce } from "../../decorators/debounce.decorator";
import { S25ModalComponent } from "../s25-modal/s25.modal.component";
import { DaysOfWeekPref, PreferenceService } from "../../services/preference.service";
import { S25DatetimePrefssUtil } from "../s25-datetime-prefs/s25.datetime.prefs.util";
import { Memo } from "../../decorators/memo.decorator";
import { AriaLive } from "../../services/aria.live.service";
import { Bind } from "../../decorators/bind.decorator";
import { EventData, EventService } from "../../services/event.service";
import { AvailUtil } from "./avail.util";
import { S25InfiniteScrollDirective } from "../s25-infinite-scroll/s25.infinite.scroll.directive";
import Ids = Item.Ids;
import { Telemetry } from "../../decorators/telemetry.decorator";
import { TelemetryService } from "../../services/telemetry.service";

@TypeManagerDecorator("s25-ng-availability-grid")
@Component({
    selector: "s25-ng-availability-grid",
    template: `
        @if (optInit) {
            <s25-ng-opt
                [modelBean]="optBean"
                [date]="date"
                [dateFormat]="dateFormat"
                [showLoading]="isLoading"
                [snapToGrid]="true"
                [lastUpdate]="data?.lastUpdate"
                (refreshed)="refresh()"
                (dateChange)="onDateChange($event)"
                (daysChange)="onDaysChange($event)"
                (includeRequestedChange)="onIncludeRequestedChange($event)"
                (modeChange)="onModeChange($event)"
                (snapToGridChange)="onSnapToGridChange($event)"
                (utilizationViewChange)="onUtilizationViewChange($event)"
                (queryChange)="onQueryChange($event)"
                (tabChange)="onTabChange($event)"
            ></s25-ng-opt>
        }
        @if (isInit && !isLoading && !data?.rows?.length) {
            <div class="noResults">
                <span>No search results were found.</span>
            </div>
        }
        @if (isInit && data?.rows?.length) {
            <div
                class="availGrid"
                [attr.data-mode]="mode"
                [attr.data-view]="view"
                [attr.data-utilization-view]="utilizationView"
                [class.isLoading]="isLoading"
                [class.isMoving]="!!moveItem"
                [class.isResizing]="!!resizeItem"
                [class.isCreating]="!!pen?.create"
                [class.isKeyboardEdit]="moveItem?.isKeyboard || resizeItem?.isKeyboard"
                [class.isMobile]="isMobile"
                s25-infinite-scroll
                [topSelector]="'window'"
                [onScroll]="infiniteScroll"
                [hasMorePages]="hasMorePages"
                [minScrollAmount]="100"
            >
                <div class="gridRow headerRow">
                    <div class="firstColumn">
                        <s25-ng-office-hours-slider
                            (onChange)="onHoursChange($event.start, $event.end)"
                        ></s25-ng-office-hours-slider>
                    </div>
                    @if (!isMobile) {
                        <div class="headers">
                            @for (header of data.headers; track header) {
                                <div class="header" [class.pm]="header.isPm">
                                    {{ header.text }}
                                </div>
                            }
                        </div>
                    }
                </div>
                <div class="body" role="grid">
                    @if (!isMobile) {
                        <div class="overlay">
                            <div class="gridOverlay">
                                @if (currentTimeMarker >= 0 && currentTimeMarker <= 100) {
                                    <div class="currentTimeMarker" [style.left]="currentTimeMarker + '%'"></div>
                                }
                                <div class="markers">
                                    @for (header of data.headers; track header; let i = $index) {
                                        <div class="marker thick"></div>
                                        <div class="marker"></div>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    @for (row of data.rows; track row) {
                        <div class="gridRow availRow" [attr.data-text]="row.text" role="row">
                            @if (isMobile) {
                                <div class="gridRow headerRow">
                                    <div class="headers">
                                        @for (header of data.headers; track header) {
                                            <div class="header" [class.pm]="header.isPm">
                                                {{ header.text }}
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                            @if (isMobile) {
                                <div class="overlay">
                                    <div class="gridOverlay">
                                        @if (currentTimeMarker >= 0 && currentTimeMarker <= 100) {
                                            <div class="currentTimeMarker" [style.top]="currentTimeMarker + '%'"></div>
                                        }
                                        <div class="markers">
                                            @for (header of data.headers; track header; let i = $index) {
                                                <div class="marker thick"></div>
                                                <div class="marker"></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            <div class="firstColumn" [tabindex]="row.itemType > 0 ? -1 : 0" role="rowheader">
                                @if (data.hasStar) {
                                    <s25-ng-favorite-simple
                                        [starred]="row.fav"
                                        [itemId]="row.id"
                                        [itemType]="row.itemType"
                                        [itemName]="row.text"
                                        [loggedIn]="loggedIn"
                                    ></s25-ng-favorite-simple>
                                }
                                @if (row.itemType > 0) {
                                    <s25-item-generic
                                        [includeTypeIcon]="true"
                                        [modelBean]="{ itemTypeId: this.itemType, itemId: row.id, itemName: row.text }"
                                    ></s25-item-generic>
                                }
                                @if (row.itemType < 0) {
                                    <span>{{ row.text }}</span>
                                }
                            </div>
                            <div class="tracks">
                                @for (track of row.tracks; track track) {
                                    <div
                                        class="track"
                                        (mouseenter)="showPen($event, row, track)"
                                        (mousemove)="updatePen($event, track)"
                                        (mouseleave)="hidePen($event, track)"
                                    >
                                        @if (
                                            loggedIn &&
                                            allowEventCreation &&
                                            pen?.track === track &&
                                            canCreateEventAt(row, pen.hours, pen.hours + 0.5)
                                        ) {
                                            <div
                                                class="pen"
                                                [class.creating]="!!pen?.create"
                                                [style.left]="!isMobile ? pen.style.left : 0"
                                                [style.top]="!isMobile ? 0 : pen.style.left"
                                                [style.width]="pen.style.width"
                                                [style.height]="isMobile && pen.style.width"
                                                (mousedown)="onPenMousedown($event, row.date, track)"
                                            >
                                                @if (!!pen?.create) {
                                                    <s25-ng-time-bubble
                                                        [position]="'up'"
                                                        [time]="pen.timeLabels.start"
                                                    ></s25-ng-time-bubble>
                                                }
                                                <div class="penWrapper"><s25-ng-icon [type]="'pen'"></s25-ng-icon></div>
                                                @if (!!pen?.create) {
                                                    <s25-ng-time-bubble
                                                        [position]="'down'"
                                                        [time]="pen.timeLabels.end"
                                                    ></s25-ng-time-bubble>
                                                }
                                            </div>
                                        }
                                        @for (item of track; track item) {
                                            @if (
                                                item.itemType !== 999 ||
                                                item.blockedCandidateId === moveItem?.item.candidateId ||
                                                item.blockedCandidateId === resizeItem?.item.candidateId
                                            ) {
                                                <div
                                                    class="item"
                                                    role="gridcell"
                                                    [class.notEditable]="!item.gridData.canEdit"
                                                    [class.moving]="moveItem?.item === item"
                                                    [class.resizing]="resizeItem?.item === item"
                                                    [class.copy]="item.gridData?.isCopy"
                                                    [class.noUtilization]="!(item.utilization[utilizationView] >= 0)"
                                                    [attr.data-type]="item.type"
                                                    [style.left]="!isMobile ? item.gridData?.style.itemLeft : 0"
                                                    [style.top]="
                                                        !isMobile
                                                            ? item.gridData?.style.itemTop
                                                            : item.gridData?.style.itemLeft
                                                    "
                                                    [style.width]="!isMobile ? item.gridData?.style.itemWidth : '100%'"
                                                    [style.height]="
                                                        isMobile
                                                            ? item.gridData?.style.itemWidth
                                                            : 'var(--avail-item-height)'
                                                    "
                                                    [style.background-color]="item.gridData?.style.backgroundColor"
                                                    [style.color]="item.gridData?.style.color"
                                                    [style.background-image]="item.gridData?.style.pattern"
                                                    (mousedown)="startMoveItemMouse($event, item)"
                                                    s25-ng-capture-event
                                                    (capture.contextmenu)="itemContextMenu($event, item)"
                                                    (mouseenter)="hidePen($event, track)"
                                                    (mouseleave)="showPen($event, row, track)"
                                                    [tabindex]="!!item.id && mode !== 'edit' ? -1 : 0"
                                                    [attr.aria-label]="item.gridData?.ariaLabel"
                                                    (keydown.enter)="toggleMoveItemKeyboard($event, item)"
                                                    (keydown.arrowUp)="updateMoveItemKeyboard($event)"
                                                    (keydown.arrowDown)="updateMoveItemKeyboard($event)"
                                                    (keydown.arrowLeft)="updateMoveItemKeyboard($event)"
                                                    (keydown.arrowRight)="updateMoveItemKeyboard($event)"
                                                    (capture.blur)="stopMoveItemKeyboard($event)"
                                                    (keydown.escape)="stopMoveItemKeyboard($event)"
                                                >
                                                    @if (!!item.id) {
                                                        <s25-item-event
                                                            [modelBean]="{
                                                                itemId: item.id,
                                                                itemId2: item.reservationId,
                                                                itemName: item.text,
                                                            }"
                                                            [empty]="true"
                                                            [inactive]="mode === 'edit'"
                                                            [class.s25-object-event]="mode !== 'edit'"
                                                            [ariaLabel]="item.gridData?.ariaLabel"
                                                        >
                                                            <div class="itemContent">
                                                                @if (item.gridData.canEdit && !item.gridData.isCopy) {
                                                                    <div
                                                                        class="resizeHandle left"
                                                                        (mousedown)="
                                                                            startResizeItemMouse($event, item, 'left')
                                                                        "
                                                                        [tabindex]="0"
                                                                        [attr.aria-label]="
                                                                            'Resize Handle, Press enter to start changing the start time'
                                                                        "
                                                                        role="slider"
                                                                        [attr.aria-valuenow]="
                                                                            resizeItem?.new?.start || item.start
                                                                        "
                                                                        (keydown.enter)="
                                                                            toggleResizeItemKeyboard(
                                                                                $event,
                                                                                item,
                                                                                'left'
                                                                            )
                                                                        "
                                                                        (keydown.arrowLeft)="
                                                                            updateResizeItemKeyboard($event, -1)
                                                                        "
                                                                        (keydown.arrowRight)="
                                                                            updateResizeItemKeyboard($event, 1)
                                                                        "
                                                                        (keydown.escape)="stopResizeItem($event)"
                                                                        s25-ng-capture-event
                                                                        (capture.blur)="stopResizeItem($event)"
                                                                    ></div>
                                                                }
                                                                @if (
                                                                    moveItem?.item === item || resizeItem?.item === item
                                                                ) {
                                                                    <s25-ng-time-bubble
                                                                        [position]="'up'"
                                                                        [time]="item.gridData.timeLabels.pre"
                                                                    ></s25-ng-time-bubble>
                                                                }
                                                                @if (item.eventStart) {
                                                                    <div
                                                                        class="colorOverlay setup"
                                                                        [style.width]="
                                                                            !isMobile
                                                                                ? item.gridData.style.setupWidth
                                                                                : '100%'
                                                                        "
                                                                        [style.height]="
                                                                            isMobile
                                                                                ? 'calc(' +
                                                                                  item.gridData.style.setupWidth +
                                                                                  ' - 4px)'
                                                                                : 'calc(var(--avail-item-height) - 2 * var(--avail-border-width))'
                                                                        "
                                                                    ></div>
                                                                }
                                                                @if (
                                                                    (!item.eventStart || item.eventStart < 100) &&
                                                                    (!item.eventEnd || item.eventEnd > 0)
                                                                ) {
                                                                    @if (
                                                                        (moveItem?.item === item ||
                                                                            resizeItem?.item === item) &&
                                                                        item.eventStartTime > item.start
                                                                    ) {
                                                                        <s25-ng-time-bubble
                                                                            [position]="'down'"
                                                                            [time]="item.gridData.timeLabels.start"
                                                                        ></s25-ng-time-bubble>
                                                                    }
                                                                    <div
                                                                        class="itemText"
                                                                        [class.noSetup]="!item.eventStart"
                                                                        [class.noTakedown]="!item.eventEnd"
                                                                        [style.width]="
                                                                            !isMobile
                                                                                ? item.gridData.style.eventWidth
                                                                                : '100%'
                                                                        "
                                                                        [style.height]="
                                                                            isMobile
                                                                                ? item.gridData.style.eventWidth
                                                                                : 'calc(var(--avail-item-height) - 2 * var(--avail-border-width))'
                                                                        "
                                                                    >
                                                                        <ng-container
                                                                            *ngTemplateOutlet="
                                                                                itemTextContent;
                                                                                context: { item: item }
                                                                            "
                                                                        ></ng-container>
                                                                    </div>
                                                                    @if (
                                                                        (moveItem?.item === item ||
                                                                            resizeItem?.item === item) &&
                                                                        item.eventEndTime &&
                                                                        item.eventEndTime < item.end
                                                                    ) {
                                                                        <s25-ng-time-bubble
                                                                            [position]="'up'"
                                                                            [time]="item.gridData.timeLabels.end"
                                                                        ></s25-ng-time-bubble>
                                                                    }
                                                                }
                                                                @if (item.eventEnd) {
                                                                    <div
                                                                        class="colorOverlay takedown"
                                                                        [style.width]="
                                                                            !isMobile
                                                                                ? item.gridData.style.takedownWidth
                                                                                : '100%'
                                                                        "
                                                                        [style.height]="
                                                                            isMobile
                                                                                ? 'calc(' +
                                                                                  item.gridData.style.takedownWidth +
                                                                                  ' - 5px)'
                                                                                : 'calc(var(--avail-item-height) - 2 * var(--avail-border-width))'
                                                                        "
                                                                    ></div>
                                                                }
                                                                @if (
                                                                    moveItem?.item === item || resizeItem?.item === item
                                                                ) {
                                                                    <s25-ng-time-bubble
                                                                        [position]="'down'"
                                                                        [time]="item.gridData.timeLabels.post"
                                                                    ></s25-ng-time-bubble>
                                                                }
                                                                @if (item.gridData.canEdit && !item.gridData.isCopy) {
                                                                    <div
                                                                        class="resizeHandle right"
                                                                        (mousedown)="
                                                                            startResizeItemMouse($event, item, 'right')
                                                                        "
                                                                        [tabindex]="0"
                                                                        [attr.aria-label]="
                                                                            'Resize Handle, Press enter to start changing the end time'
                                                                        "
                                                                        role="slider"
                                                                        [attr.aria-valuenow]="
                                                                            resizeItem?.new?.end || item.end
                                                                        "
                                                                        (keydown.enter)="
                                                                            toggleResizeItemKeyboard(
                                                                                $event,
                                                                                item,
                                                                                'right'
                                                                            )
                                                                        "
                                                                        (keydown.arrowLeft)="
                                                                            updateResizeItemKeyboard($event, -1)
                                                                        "
                                                                        (keydown.arrowRight)="
                                                                            updateResizeItemKeyboard($event, 1)
                                                                        "
                                                                        (keydown.escape)="stopResizeItem($event)"
                                                                        s25-ng-capture-event
                                                                        (capture.blur)="stopResizeItem($event)"
                                                                    ></div>
                                                                }
                                                            </div>
                                                        </s25-item-event>
                                                    }
                                                    @if (!item.id) {
                                                        <div class="itemContent">
                                                            @if (item.eventStart) {
                                                                <div
                                                                    class="colorOverlay setup"
                                                                    [style.width]="
                                                                        !isMobile
                                                                            ? item.gridData.style.setupWidth
                                                                            : '100%'
                                                                    "
                                                                    [style.height]="
                                                                        isMobile
                                                                            ? 'calc(' +
                                                                              item.gridData.style.setupWidth +
                                                                              ' - 4px)'
                                                                            : 'calc(var(--avail-item-height) - 2 * var(--avail-border-width))'
                                                                    "
                                                                ></div>
                                                            }
                                                            <span
                                                                class="itemText"
                                                                [class.noSetup]="!item.eventStart"
                                                                [class.noTakedown]="!item.eventEnd"
                                                                [title]="item.text"
                                                                [style.width]="
                                                                    !isMobile ? item.gridData.style.eventWidth : '100%'
                                                                "
                                                                [style.height]="
                                                                    isMobile
                                                                        ? item.gridData.style.eventWidth
                                                                        : 'calc(var(--avail-item-height) - 2 * var(--avail-border-width))'
                                                                "
                                                            >
                                                                <ng-container
                                                                    *ngTemplateOutlet="
                                                                        itemTextContent;
                                                                        context: { item: item }
                                                                    "
                                                                ></ng-container>
                                                            </span>
                                                            @if (item.eventEnd) {
                                                                <div
                                                                    class="colorOverlay takedown"
                                                                    [style.width]="
                                                                        !isMobile
                                                                            ? item.gridData.style.takedownWidth
                                                                            : '100%'
                                                                    "
                                                                    [style.height]="
                                                                        isMobile
                                                                            ? 'calc(' +
                                                                              item.gridData.style.takedownWidth +
                                                                              ' - 5px)'
                                                                            : 'calc(var(--avail-item-height) - 2 * var(--avail-border-width))'
                                                                    "
                                                                ></div>
                                                            }
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
                <div class="footer"></div>
                @if (data.rows.length > 50) {
                    <button class="scrollToTop" (click)="scrollToTop()">
                        <s25-ng-icon [type]="'arrowUp'" [label]="'Scroll To Top'"></s25-ng-icon>
                    </button>
                }
            </div>
        }
        @if (isInit && isLoading) {
            <s25-ng-loading-inline-static></s25-ng-loading-inline-static>
        }

        <ng-template #itemTextContent let-item="item">
            <div>
                @if (utilizationView !== "none" && item.utilization[utilizationView] >= 0) {
                    <span class="utilization">{{ item.utilization[utilizationView] }}% - </span>
                }
                <span>{{ item.text }}</span>
            </div>
        </ng-template>

        <s25-ng-modal #multipleOccurrencesModal [title]="'Occurrence Edit Confirmation'">
            <div class="modalOptions">
                <h2>This event has multiple occurrences. Do you want to:</h2>
                <button class="aw-button aw-button--outline" (click)="multipleOccurrencesModal.close(0)">
                    Update this occurrence
                </button>
                <button class="aw-button aw-button--outline" (click)="multipleOccurrencesModal.close(1)">
                    Update this and future occurrences
                </button>
                <button class="aw-button aw-button--outline" (click)="multipleOccurrencesModal.close(2)">
                    Update all occurrences
                </button>
                <button class="aw-button aw-button--outline" (click)="multipleOccurrencesModal.close(-1)">
                    Cancel
                </button>
            </div>
        </s25-ng-modal>

        <s25-ng-modal #hasResourcesModal [title]="'Occurrence Edit Confirmation'" [type]="'continue'">
            This reservation has associated resources; do you want to move them to the new time or cancel?
        </s25-ng-modal>

        <s25-ng-modal #deleteOccurrenceModal [title]="'Confirm Deletion'" [type]="'delete'">
            Deleting this occurrence will remove all occurrence data as well as any associated location/resource
            assignments, and any pending location/resource assignment requests. This action cannot be reversed.
        </s25-ng-modal>
    `,
    styles: [``],
})
export class S25AvailabilityGridComponent implements OnInit {
    @Input() dataService: AvailDataService;
    @Input() view: AvailCompType;
    @Input() query: string;
    @Input() itemId: number; // Only needed for specific item grids
    @Input() itemType: Item.Id; // Only needed for specific item grids
    @Input() date: Date;
    @Input() queryGenerator: () => Promise<{ query: string; postSearch?: () => void }>; // Primarily for temp searches. If set, this will be called to get the query
    @Input() tabChangeCallback: (tab: "availabilityWeekly" | "list" | "availability" | "calendar") => void; // ONLY FOR INTERFACE WITH JS COMPONENTS. USE @Output FOR TS COMPONENTS

    @Output() tabChange = new EventEmitter<"availabilityWeekly" | "list" | "availability" | "calendar">();

    @ViewChild(S25InfiniteScrollDirective) infiniteScrollDirective: S25InfiniteScrollDirective;
    @ViewChild("multipleOccurrencesModal") multipleOccurrencesModal: S25ModalComponent;
    @ViewChild("hasResourcesModal") hasResourcesModal: S25ModalComponent;
    @ViewChild("deleteOccurrenceModal") deleteOccurrenceModal: S25ModalComponent;

    isInit = false;
    optInit = false;
    isLoading = true;
    data: AvailData;
    currentPage = 0;
    officeHours: OfficeHours; // Office hours are needed for the current time indicator and event widths
    colorMap: ColorBucketType;
    optBean: OptBean;
    instanceTimezone: Flavor<string, "IANATimezone">;
    userTimezone: Flavor<string, "IANATimezone">;
    visibleDays = new Set<0 | 1 | 2 | 3 | 4 | 5 | 6>([0, 1, 2, 3, 4, 5, 6]);
    includeRequested: boolean;
    mode: AvailMode;
    moveItem: AvailMoveItem;
    resizeItem: AvailResizeItem;
    is24Hours: boolean;
    snapToGridUnit = 30; // 30 because snap to grid is on by default in opt bar
    pen: AvailPen;
    loggedIn: boolean;
    allowEventCreation: boolean;
    perms: AvailGridPerms = { location: new Map(), resource: new Map() };
    currentTimeMarker: number; // CSS left property for current time-line
    eventData: EventData;
    utilizationView: OptUtilizationView = "none";
    dateFormat: string;
    dataPromise: Promise<[AvailData, any]>;
    isMobile: boolean;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    async ngOnInit() {
        this.setMobileView();

        this.date ??= new Date();

        const [_, colorMap, instanceTimezone, userTimezone, is24Hours, loggedIn, eventData, dateFormat] =
            await Promise.all([
                this.initOptBar(),
                ItemColorMappingService.getEnabledBucketType(),
                PreferenceService.getPreferences(["timezone"], "S"),
                UserprefService.getTZName(),
                UserprefService.getIs24HourTime(),
                UserprefService.getLoggedIn(),
                this.itemType === Ids.Event && EventService.getEventInclude(this.itemId, ["reservations"]),
                UserprefService.getS25Dateformat(),
            ]);
        if (this.itemId) this.itemId = Number(this.itemId);
        this.colorMap = colorMap;
        this.instanceTimezone = S25DatetimePrefssUtil.timeZoneToIANA[instanceTimezone?.timezone?.value || 25];
        this.userTimezone = userTimezone;
        this.is24Hours = is24Hours;
        this.loggedIn = loggedIn;

        // We use window.angBridge.S25Const here instead of S25Const because S25Const does not have allowEmbeddedEventCreation from the JS
        this.allowEventCreation =
            this.view !== "availability_schedule" && (!S25Util.isInIframe || S25Const.allowEmbeddedEventCreation);
        if (this.itemType === Ids.Event) {
            this.date = new Date(eventData?.start_date);
            this.eventData = eventData;
        }
        this.dateFormat = dateFormat;
        await this.refresh();
        setInterval(this.setCurrentTimeMarker, S25Const.ms.min);
        this.isInit = true;
        this.changeDetector.detectChanges();

        this.setMobileView();
    }

    async initOptBar() {
        const optBean = await OptUtil.getOptBean(this.view, this.query, this.itemId, this.itemType);
        this.mode = !!optBean.editable?.value ? "edit" : Number(optBean.separated?.value) ? "separated" : "overlapping";
        this.query = this.query || (optBean.chosen?.obj?.val && `&${optBean.chosen?.obj?.val}`);
        this.optBean = optBean;
        this.optInit = true;
        this.changeDetector.detectChanges();
    }

    @Debounce(300) // Avoid double calls
    async refresh() {
        AriaLive.announce("Refreshing Availability");
        this.setLoading(true);
        this.currentPage = 0;
        const { view, itemType, date, includeRequested, mode } = this;
        const { query, postSearch } = (await this.queryGenerator?.()) ?? { query: this.query };
        const promise = S25Util.Maybe(
            this.dataService({ view, itemType, query, startDate: date, includeRequested, mode }),
        );
        this.dataPromise = promise;
        const [data, error] = await promise;
        postSearch?.();
        if (this.dataPromise !== promise) return; // This search has been superseded by another. Discard.
        if (!data || error) {
            this.setLoading(false);
            return alert("Something went wrong when loading availability data");
        }
        this.data = data;
        this.officeHours = AvailUtil.extractOfficeHours(this.data);
        AvailUtil.filterDaysOfWeek(this.data, this.visibleDays);
        this.updatePerms(this.data.perms);
        this.setEventData(this.data);
        this.setCurrentTimeMarker();
        this.setLoading(false);
        this.infiniteScrollDirective?.setScrollThresholdPx();
        this.infiniteScrollDirective?.infinScrollHandler(); // So that more data can be loaded if not enough is shown be default
        AriaLive.announce("Refresh Complete");
    }

    @Bind
    async infiniteScroll() {
        if (this.isLoading) return; // Avoid simultaneous data calls
        if (this.elementRef.nativeElement.offsetWidth === 0) return; // Ignore scroll actions when component is hidden

        AriaLive.announce("Loading more rows");
        this.setLoading(true);
        this.currentPage += 1;
        const { view, itemType, date, includeRequested, mode, currentPage } = this;
        const { query, postSearch } = (await this.queryGenerator?.()) ?? { query: this.query };
        const { locationKey, resourceKey } = this.perms;
        const [data, error] = await S25Util.Maybe(
            this.dataService({
                view,
                itemType,
                query,
                startDate: date,
                includeRequested,
                mode,
                page: currentPage,
                lastId: this.data.lastId,
                locationKey,
                resourceKey,
            }),
        );
        postSearch?.();
        if (error) {
            this.setLoading(false);
            return S25Util.showError(error, "Something went wrong while loading more rows");
        }
        this.data.lastId = data.lastId;
        this.data.pages = data.pages;
        AvailUtil.filterDaysOfWeek(data, this.visibleDays);
        this.updatePerms(data.perms);
        this.setEventData(data);
        this.data.rows.push(...data.rows);

        this.setLoading(false);
        AriaLive.announce("Finished loading");
    }

    // -----------------------------------------------------------------------------------------------------------------
    // EVENTS
    // -----------------------------------------------------------------------------------------------------------------

    async onHoursChange(start: number, end: number) {
        await this.refresh();
        this.setMobileView();
    }

    async onDateChange(date: Date) {
        this.date = date;
        await this.refresh();
        this.setMobileView();
    }

    async onDaysChange(days: DaysOfWeekPref) {
        const newDaysOfWeek = days.days.day.filter((day) => day.show === "true").map((day) => day.dow);
        this.visibleDays = new Set(newDaysOfWeek);
        await this.refresh();
        this.setMobileView();
    }

    async onIncludeRequestedChange(yes: boolean) {
        this.includeRequested = yes;
        await this.refresh();
        this.setMobileView();
    }

    onModeChange(mode: AvailMode) {
        if (!this.data) return;
        const shouldRefresh = mode === "edit" || this.mode === "edit"; // Refresh when switching to or from edit mode
        this.mode = mode;
        if (shouldRefresh) this.refresh();
        else {
            for (let row of this.data.rows) {
                const items = row.tracks.reduce((all, track) => all.concat(track), []);
                // For overlapping mode, put everything into one track. For separated mode, use separate tracks
                if (mode === "overlapping") row.tracks = AvailService.getOverlappingTrack(items);
                else row.tracks = AvailService.getTracksOverlayConflicts(items);
            }
            this.changeDetector.detectChanges();
        }
    }

    @HostListener("document:mousemove", ["$event"])
    onMouseMove(event: MouseEvent) {
        if (this.moveItem && !this.moveItem?.isKeyboard) return this.updateMoveItemMouse(event);
        if (this.resizeItem && !this.resizeItem?.isKeyboard) return this.updateResizeItemMouse(event);
        if (this.pen?.create) return this.movePenCreate(event);
    }

    @HostListener("document:mouseup", ["$event"])
    onMouseUp(event: MouseEvent) {
        if (this.moveItem && !this.moveItem?.isKeyboard) return this.stopMoveItemMouse(event);
        if (this.resizeItem && !this.resizeItem?.isKeyboard) return this.stopResizeItem(event);
        if (this.pen?.create) return this.dropPenCreate(event);
    }

    @HostListener("window:resize", ["$event"])
    onResize() {
        this.setMobileView();
    }

    onSnapToGridChange(snap: boolean) {
        this.snapToGridUnit = snap ? 30 : 5; // If snap to grid, snap to 30 minutes, otherwise snap to 5 minutes
    }

    onUtilizationViewChange(view: OptUtilizationView) {
        this.utilizationView = view;
        this.setEventData(this.data); // We really just need to update styling, but this works
        this.changeDetector.detectChanges();
    }

    async onQueryChange(query: string) {
        this.query = query;
        this.queryGenerator = null;
        await this.refresh();
        this.setMobileView();
    }

    @Bind
    onQueryGeneratorChange(queryGenerator: () => Promise<{ query: string; postSearch?: () => void }>) {
        this.queryGenerator = queryGenerator;
        return this.refresh();
    }

    onTabChange(tab: "availabilityWeekly" | "list" | "availability" | "calendar") {
        this.tabChangeCallback?.(tab);
        this.tabChange.emit(tab);
    }

    // -----------------------------------------------------------------------------------------------------------------
    // INTERFACE
    // -----------------------------------------------------------------------------------------------------------------

    copyItem(item: AvailItem) {
        AvailUtil.copyItem(item);
        this.changeDetector.detectChanges();
    }

    relocateItem(data: { item: AvailItem; start: number; end: number; destination: AvailRow }) {
        this.zone.run(() => {
            AvailUtil.relocateItem(data);
        });
        this.setEventData(this.data);
        this.changeDetector.detectChanges();
    }

    // -----------------------------------------------------------------------------------------------------------------
    // DRAG AND DROP
    // -----------------------------------------------------------------------------------------------------------------

    startMoveItemMouse(event: MouseEvent, item: AvailItem) {
        if (this.moveItem || event.button !== 0 || !item.gridData.canEdit) return;
        this.moveItem = AvailUtil.startMovingItem(event, item, this.is24Hours);
        Object.assign(this.moveItem.init, { x: event.pageX, y: event.pageY });
        this.changeDetector.detectChanges();
    }

    toggleMoveItemKeyboard(event: KeyboardEvent, item: AvailItem) {
        if (!item.gridData.canEdit || (this.moveItem && !this.moveItem.isKeyboard)) return;
        if (!this.moveItem) return this.startMoveItemKeyboard(event, item);
        return this.stopMoveItemKeyboard(event);
    }

    startMoveItemKeyboard(event: KeyboardEvent, item: AvailItem) {
        if (this.moveItem || !item.gridData.canEdit) return;
        this.moveItem = AvailUtil.startMovingItem(event, item, this.is24Hours);
        this.moveItem.isKeyboard = true;
        this.moveItem.currentTrack = (event.target as HTMLElement).closest(".track");
        this.changeDetector.detectChanges();
    }

    updateMoveItemMouse(event: MouseEvent) {
        if (!this.moveItem) return;
        const { init, item } = this.moveItem;
        const offset = AvailUtil.getOffsetFromMouse(event, this.moveItem);
        item.gridData.style.itemLeft = `${init.itemLeft + offset.x}px`;
        item.gridData.style.itemTop = `${init.itemTop + offset.y}px`;
        let hoursMoved = this.pixelsToHours(offset.x);
        this.updateMoveItem(hoursMoved);
    }

    updateMoveItemKeyboard(event: KeyboardEvent) {
        if (!this.moveItem?.isKeyboard) return;
        event.preventDefault();
        const { init, item, currentTrack, maxOffset } = this.moveItem;
        const hoursMoved = (this.moveItem.new?.start || item.start) - item.start;
        const unit = event.shiftKey ? 1 : this.snapToGridUnit / 60;
        if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
            if (event.key === "ArrowLeft")
                this.updateMoveItem(Math.max(hoursMoved - unit, this.officeHours.start - item.start));
            else if (event.key === "ArrowRight")
                this.updateMoveItem(Math.min(hoursMoved + unit, this.officeHours.end - item.end));
            const ariaStart = AvailUtil.getAriaTime(this.moveItem.new.start, this.is24Hours);
            const ariaEnd = AvailUtil.getAriaTime(this.moveItem.new.end, this.is24Hours);
            AriaLive.announce(`${ariaStart} to ${ariaEnd}`, true);
        } else if (event.key === "ArrowUp" || event.key === "ArrowDown") {
            if (event.key === "ArrowUp") this.moveItem.currentTrack = AvailUtil.findPreviousTrack(currentTrack);
            else if (event.key === "ArrowDown") this.moveItem.currentTrack = AvailUtil.findNextTrack(currentTrack);
            AriaLive.announce(this.moveItem.currentTrack.closest(".availRow").getAttribute("data-text"), true);
        }
        const { start, end, eventStartTime, eventEndTime } = item;
        this.moveItem.new ??= { start, end, eventStartTime, eventEndTime }; // Ensure that we don't reset because there is no "new" time
        const offsetX = this.hoursToPixels((this.moveItem.new?.start || item.start) - item.start);
        item.gridData.style.itemLeft = `${init.itemLeft + offsetX}px`;
        item.gridData.style.itemTop = `${maxOffset.top + this.moveItem.currentTrack.offsetTop + 1}px`;
        this.changeDetector.detectChanges();
    }

    updateMoveItem(hoursMoved: number) {
        this.moveItem.new = AvailUtil.updateMoveItem(this.moveItem.item, hoursMoved, this.snapToGridUnit);
        this.moveItem.item.gridData.timeLabels = AvailUtil.getEventTimeLabels(this.moveItem.new, this.is24Hours);
        this.changeDetector.detectChanges();
    }

    stopMoveItemMouse(event: MouseEvent) {
        const { moveItem, data } = this;
        const destination = AvailUtil.getTrackFromMouseOffset(event, moveItem, data.rows);
        return this.stopMoveItem(destination);
    }

    stopMoveItemKeyboard(event: Event) {
        if (!this.moveItem?.isKeyboard) return;
        const text = this.moveItem.currentTrack.closest(".availRow").getAttribute("data-text");
        const destination = this.data.rows.find((row) => row.text === text);
        return this.stopMoveItem(destination);
    }

    stopMoveItem(destination: AvailRow) {
        const moveItem = this.moveItem;
        this.moveItem = null;
        const { item } = moveItem;
        const { start, end } = moveItem.new || {};

        if (!moveItem.new || (item.start === start && destination === item.gridData.row)) {
            AriaLive.announce(`Dropped item`, true);
            return this.resetItemStyling(item); // No change, ignore
        }
        const ariaStart = AvailUtil.getAriaTime(start, this.is24Hours);
        const ariaEnd = AvailUtil.getAriaTime(end, this.is24Hours);
        AriaLive.announce(`Placed event at ${ariaStart} to ${ariaEnd}, ${destination.text}`, true);
        return this.moveReservation({ item, start, end, destination });
    }

    // -----------------------------------------------------------------------------------------------------------------
    // EXPAND / CONTRACT
    // -----------------------------------------------------------------------------------------------------------------

    startResizeItemMouse(event: MouseEvent, item: AvailItem, side: "left" | "right") {
        if (event.button !== 0 || !item.gridData.canEdit) return;
        event.stopPropagation();
        this.resizeItem = AvailUtil.startResizingItem(event, item, side, this.officeHours);
        this.resizeItem.init.x = event.pageX;
        this.changeDetector.detectChanges();
    }

    toggleResizeItemKeyboard(event: KeyboardEvent, item: AvailItem, side: "left" | "right") {
        if (!item.gridData.canEdit || (this.resizeItem && !this.resizeItem.isKeyboard)) return;
        event.stopPropagation();
        if (this.resizeItem) return this.stopResizeItem(event);
        this.resizeItem = AvailUtil.startResizingItem(event, item, side, this.officeHours);
        this.resizeItem.isKeyboard = true;
        this.changeDetector.detectChanges();
    }

    updateResizeItemMouse(event: MouseEvent) {
        this.updateResizeItem(this.pixelsToHours(event.pageX - this.resizeItem.init.x));
    }

    updateResizeItemKeyboard(event: KeyboardEvent, direction: -1 | 1) {
        if (!this.resizeItem?.isKeyboard) return;
        const key = this.resizeItem.side === "left" ? "start" : "end";
        const currentOffset = (this.resizeItem.new?.[key] || this.resizeItem.item[key]) - this.resizeItem.item[key];
        const unit = event.shiftKey ? 1 : this.snapToGridUnit / 60; // 1 hour steps if shift key is held
        this.updateResizeItem(currentOffset + direction * unit);
    }

    updateResizeItem(hoursMoved: number) {
        const { side, init, item, min, max } = this.resizeItem;
        const { start, end, eventStartTime, eventEndTime } = item;
        const snapMultiplier = 60 / this.snapToGridUnit;
        let offset: number;
        let newStart = start;
        let newEnd = end;
        let newEventStartTime = eventStartTime;
        let newEventEndTime = eventEndTime;

        if (side === "left") {
            hoursMoved = AvailUtil.adjustResizeItem(start, eventStartTime, hoursMoved, min, max, snapMultiplier);
            newStart += hoursMoved;
            newEventStartTime += hoursMoved;
            offset = this.hoursToPixels(hoursMoved);
            if (!this.canCreateEventAt(item.gridData.row, newStart, end, item.reservationId)) {
                AriaLive.announce(`Unable to further adjust start time due to assignment policy `, true);
                return AvailUtil.updateResizeMinMax(this.resizeItem, start, newStart, this.snapToGridUnit);
            }

            item.gridData.style.itemLeft = `${init.itemLeft + offset}px`; // Translate item by offset px
        } else if (side === "right") {
            hoursMoved = AvailUtil.adjustResizeItem(end, eventEndTime, hoursMoved, min, max, snapMultiplier);
            newEnd += hoursMoved;
            newEventEndTime += hoursMoved;
            offset = -this.hoursToPixels(hoursMoved);
            if (!this.canCreateEventAt(item.gridData.row, start, newEnd, item.reservationId)) {
                AriaLive.announce(`Unable to further adjust end time due to assignment policy `, true);
                return AvailUtil.updateResizeMinMax(this.resizeItem, end, newEnd, this.snapToGridUnit);
            }
        }

        item.gridData.style.itemWidth = `${init.itemWidth - offset}px`; // Extend item width by offset px
        item.gridData.style.eventWidth = `${init.eventWidth - offset}px`; // Extend event width by offset px

        this.resizeItem.new = {
            start: newStart,
            end: newEnd,
            eventStartTime: newEventStartTime,
            eventEndTime: newEventEndTime,
        };
        AriaLive.announce(AvailUtil.getAriaTime(side === "left" ? newStart : newEnd, this.is24Hours), true);

        item.gridData.timeLabels = AvailUtil.getEventTimeLabels(this.resizeItem.new, this.is24Hours);
        this.changeDetector.detectChanges();
    }

    stopResizeItem(event: Event) {
        if (!this.resizeItem) return;
        const classList = (event.target as HTMLElement).classList;
        if (classList.contains("resizeHandle") && event.target !== this.resizeItem.handle) return;
        const resizeItem = this.resizeItem;
        this.resizeItem = null;
        const { item } = resizeItem;
        const { start, end } = resizeItem.new || {};
        if (!resizeItem.new || (item.start === start && item.end === end)) return this.resetItemStyling(item); // No change, ignore

        return this.moveReservation({ item, start, end, destination: item.gridData.row });
    }

    // -----------------------------------------------------------------------------------------------------------------
    // PEN
    // -----------------------------------------------------------------------------------------------------------------

    showPen(event: MouseEvent, row: AvailRow, track: AvailRow["tracks"][0]) {
        if (this.moveItem || this.resizeItem) return; // Ignore if editing an item
        if (!!this.pen?.create) return; // Ignore if currently creating an event
        const trackElement = (event.target as HTMLElement).closest(".track") as HTMLElement;
        const { hours, style } = this.getPenPosition(trackElement, event);
        this.pen = { row, hours, style, track, trackElement };
    }

    updatePen(event: MouseEvent, track: AvailRow["tracks"][0]) {
        if (!this.pen || !!this.pen?.create) return;
        this.pen = { ...this.pen, ...this.getPenPosition(this.pen.trackElement, event) };
        this.changeDetector.detectChanges();
    }

    getPenPosition(trackElement: HTMLElement, event: MouseEvent) {
        const rect = trackElement.getBoundingClientRect();
        let edge = S25Util.clamp((event.pageX - rect.left) / rect.width, 0, 1); // Edge is left
        if (this.isMobile) edge = S25Util.clamp((event.clientY - rect.top) / rect.height, 0, 1); // Edge is top
        let hoursFromEdge = edge * (this.officeHours.end - this.officeHours.start);
        hoursFromEdge = Math.floor(hoursFromEdge * 2) / 2; // Adjust to half hour
        edge = hoursFromEdge / (this.officeHours.end - this.officeHours.start); // Fraction from left
        const hours = hoursFromEdge + this.officeHours.start; // Actual time
        return {
            hours,
            style: {
                left: `${edge * 100}%`,
                width: `calc(100% / ${(this.officeHours.end - this.officeHours.start) * 2})`,
            },
        };
    }

    hidePen(event: MouseEvent, track: AvailRow["tracks"][0]) {
        if (!this.pen || this.pen.track !== track || !!this.pen?.create) return;
        this.pen = null;
        this.changeDetector.detectChanges();
    }

    onPenMousedown(event: MouseEvent, date: any, track: AvailRow["tracks"][0]) {
        if (event.button !== 0 || this.pen?.track !== track) return;

        const rect = this.pen.trackElement.getBoundingClientRect();
        const init =
            this.hoursToPixels(this.pen.hours - this.officeHours.start) + (!this.isMobile ? rect.left : rect.top); // Set init to half hour mark. Adjust with rect to match event position
        AvailUtil.penStartCreate(this.pen, date, init, this.officeHours, this.is24Hours);
        this.penUpdateStyle(this.pen.hours, this.hoursToPixels(0.5)); // Default to a half-hour event
        this.changeDetector.detectChanges();
    }

    penUpdateStyle(start: number, width: number) {
        this.pen.style = {
            left: `${this.hoursToPixels(start - this.officeHours.start)}px`,
            width: `${width}px`,
        };
    }

    movePenCreate(event: MouseEvent) {
        let { init, fixedTime } = this.pen.create;
        let offset = event.pageX - init;
        if (this.isMobile) offset = event.pageY - init;
        let floatTime = fixedTime + this.pixelsToHours(offset);
        floatTime = AvailUtil.snapPenCreate(this.pen, offset, floatTime, this.snapToGridUnit);
        const [start, end] = [fixedTime, floatTime].sort((a, b) => a - b);

        if (!this.canCreateEventAt(this.pen.row, start, end)) {
            if (offset < 0) this.pen.create.min = Math.max(this.pen.create.min, floatTime + this.snapToGridUnit / 60);
            else if (offset > 0)
                this.pen.create.max = Math.min(this.pen.create.max, floatTime - this.snapToGridUnit / 60);
            return;
        }
        this.pen.create.floatTime = floatTime;
        const width = Math.abs(this.hoursToPixels(Math.abs(fixedTime - floatTime)));
        this.penUpdateStyle(start, width);
        AvailUtil.penUpdateTimeLabels(this.pen, start, end, this.is24Hours);
        this.changeDetector.detectChanges();
    }

    async dropPenCreate(event: MouseEvent) {
        const pen = this.pen;
        this.pen = null;
        this.setLoading(true);
        await AvailUtil.penCreateEvent(pen, this.itemType, this.itemId || pen.row.id);
        this.setLoading(false);
    }

    // -----------------------------------------------------------------------------------------------------------------
    // ITEM
    // -----------------------------------------------------------------------------------------------------------------

    setEventData(data: AvailData) {
        for (let row of data.rows) {
            for (let track of row.tracks) {
                for (let item of track) {
                    if (item.itemType !== 999) item.text ||= this.eventData?.event_name; // For availability schedule, set event names
                    item.gridData = AvailUtil.getItemGridData(
                        row,
                        item,
                        this.utilizationView,
                        this.colorMap,
                        this.officeHours,
                        this.is24Hours,
                    );
                    item.gridData.ap = this.getEventAP(item, row);
                    item.gridData.canEdit =
                        item.editable && item.gridData.ap.canMove && !item.outsideRange && item.hasPerm;
                    item.gridData.ariaLabel = AvailUtil.getItemAriaLabel(item, this.is24Hours);
                }
            }
        }
    }

    getPermAtTime(row: AvailRow, startHour: number, endHour: number, reservationId?: number) {
        return AvailUtil.getPermAtTime(
            this.perms,
            this.userTimezone,
            this.instanceTimezone,
            this.view,
            this.itemType,
            this.itemId,
            row,
            startHour,
            endHour,
            this.eventData,
            reservationId,
        );
    }

    getEventAP(item: AvailItem, row: AvailRow): AvailItem["gridData"]["ap"] {
        if (this.mode !== "edit") return {}; // No need to calculate AP if not in edit mode
        if (item.itemType === 999) return {}; // No need to calculate AP for conflict blocks

        const perm = this.getPermAtTime(row, item.start, item.end, item.reservationId);
        const canUnassign = AvailService.isPermEnoughTo("unassign", perm);
        const canRequest = AvailService.isPermEnoughTo("request", perm);
        return { canUnassign, canRequest, canDelete: canUnassign, canCopy: canRequest, canMove: canUnassign };
    }

    @Memo()
    canCreateEventAt(row: AvailRow, startTime: number, endTime?: number, reservationId?: number): boolean {
        const perm = this.getPermAtTime(row, startTime, endTime, reservationId);
        return AvailService.isPermEnoughTo("create", perm);
    }

    itemContextMenu(event: MouseEvent, item: AvailItem) {
        if (this.mode !== "edit" || !item.gridData.canEdit) return;
        event.stopImmediatePropagation();
        event.preventDefault();

        const contextMenu: any = {
            data: {
                perms: {
                    canDelete: item.gridData.ap.canDelete,
                    canCopy: item.gridData.ap.canCopy,
                },
                deleteRsrv: () => {
                    this.deleteReservation(item);
                    contextMenu.hidePopup();
                },
                copyRsrv: () => {
                    this.copyItem(item);
                    contextMenu.hidePopup();
                },
                rsrvDetails: () => {
                    // TODO: Switch out with TS service once migrated
                    const modalData: any = {
                        rsvId: item.reservationId,
                        delete: async () => {
                            await this.deleteReservation(item);
                            modalData.closeModal();
                        },
                        copy: () => {
                            this.copyItem(item);
                            modalData.closeModal();
                        },
                        removeItem: () => {
                            AvailUtil.removeItem(item);
                            modalData.closeModal();
                        },
                    };
                    window.angBridge.$injector.get("s25ModalService").modal("occurrence-edit", modalData);
                    contextMenu.hidePopup();
                },
            },
        };
        // TODO: Switch out with TS service once migrated
        window.angBridge.$injector.get("ContextMenuService").createMenu(19991, item.id, event, contextMenu);
    }

    // -----------------------------------------------------------------------------------------------------------------
    // SERVICE INTERFACE
    // -----------------------------------------------------------------------------------------------------------------

    async moveReservation(data: {
        item: AvailItem;
        start: number;
        end: number;
        destination: AvailRow;
        updateAllOccs?: 0 | 1 | 2;
        updateResources?: boolean;
    }): Promise<void> {
        if (data.item.gridData.isCopy) return this.copyReservation(data);
        TelemetryService.send("Avail", "UpdRsrv");
        AriaLive.announce(`Saving `);
        this.setLoading(true);
        const { item, updateAllOccs } = data;
        const canChangeLocation =
            (this.view === "availability" && this.itemType === Item.Ids.Location) || this.view === "availability_home";
        const canChangeResource = this.view === "availability" && this.itemType === Item.Ids.Resource;
        const [response, error] = await AvailUtil.updateReservation({
            view: this.view,
            start: data.start,
            end: data.end,
            updateAllOccs: data.updateAllOccs,
            updateResources: data.updateResources,
            newDate: data.destination.date,
            eventId: data.item.id,
            reservationId: data.item.reservationId,
            newLocationId: canChangeLocation && data.destination.id,
            oldLocationId: canChangeLocation && data.item.gridData.itemId,
            newResourceId: canChangeResource && data.destination.id,
            oldResourceId: canChangeResource && data.item.gridData.itemId,
        });
        if (error || response.ret < 0) {
            this.setLoading(false);
            this.resetItemStyling(item);
            const msg = error ? "There was an error while attempting to update this reservation." : response.msg;
            return S25Util.showError(error || response, msg);
        }

        // Updated successfully; now update
        if (response.ret === 0) {
            AriaLive.announce(`Saved successfully `);
            if (updateAllOccs === 1 || updateAllOccs === 2) return this.refresh(); // If multiple occurrences are updated, refresh to update all of them
            this.relocateItem(data);
            this.setLoading(false);
        }
        // Recurring reservation; user needs to decide which reservation(s) to move
        else if (response.ret === 1) {
            const answer = (await this.multipleOccurrencesModal.open()) as -1 | 0 | 1 | 2;
            // Cancelled (-1) or closed (undefined); reset positioning
            if (answer === -1 || answer === undefined) {
                this.setLoading(false);
                return this.resetItemStyling(item);
            }
            return this.moveReservation({ ...data, updateAllOccs: answer });
        }
        // Reservation has resources; user needs to decide whether they still want to move it
        else if (response.ret === 2) {
            const answer = (await this.hasResourcesModal.open()) as boolean;
            // Cancelled (false) or closed (undefined); reset positioning
            if (!answer) {
                this.setLoading(false);
                return this.resetItemStyling(item);
            }
            return this.moveReservation({ ...data, updateResources: true });
        }
    }

    async deleteReservation(item: AvailItem) {
        const yes = await this.deleteOccurrenceModal.open();
        if (!yes) return;
        TelemetryService.send("Avail", "DelRsrv");
        this.setLoading(true);
        const [ok, error] = await S25Util.Maybe(AvailService.deleteReservation(item.reservationId, item.id));
        this.setLoading(false);
        if (error) return S25Util.showError(error, "There was an error while attempting to delete this reservation.");
        AvailUtil.removeItem(item);
        this.changeDetector.detectChanges();
    }

    async copyReservation(data: { item: AvailItem; start: number; end: number; destination: AvailRow }) {
        TelemetryService.send("Avail", "CopyRsrv");
        this.setLoading(true);
        const canChangeLocation =
            (this.view === "availability" && this.itemType === Item.Ids.Location) || this.view === "availability_home";
        const canChangeResource = this.view === "availability" && this.itemType === Item.Ids.Resource;
        const [response, error] = await AvailUtil.copyReservation({
            start: data.start,
            end: data.end,
            date: data.destination.date,
            eventId: data.item.id,
            reservationId: data.item.reservationId,
            newLocationId: canChangeLocation && data.destination.id,
            oldLocationId: canChangeLocation && data.item.gridData.itemId,
            newResourceId: canChangeResource && data.destination.id,
            oldResourceId: canChangeResource && data.item.gridData.itemId,
        });
        if (error) {
            this.setLoading(false);
            return S25Util.showError(error, "There was an error while attempting to copy this reservation.");
        }
        if (this.view === "availability_schedule")
            EventService.getEventInclude(this.itemId, ["reservations"]).then((data) => (this.eventData = data));

        this.relocateItem(data);
        data.item.gridData.isCopy = false;
        this.setLoading(false);
    }

    // -----------------------------------------------------------------------------------------------------------------
    // UTILITY
    // -----------------------------------------------------------------------------------------------------------------

    setLoading(yes: boolean) {
        this.isLoading = yes;
        this.changeDetector.detectChanges();
    }

    pixelsToHours(px: number) {
        const track = this.elementRef.nativeElement.querySelector(".tracks");
        const size = !this.isMobile ? track.offsetWidth : track.offsetHeight;
        return AvailUtil.pixelsToHours(size, px, this.officeHours);
    }

    hoursToPixels(hours: number) {
        const track = this.elementRef.nativeElement.querySelector(".tracks");
        const size = !this.isMobile ? track.offsetWidth : track.offsetHeight;
        return AvailUtil.hoursToPixels(size, hours, this.officeHours);
    }

    @Bind
    setCurrentTimeMarker() {
        if (!this.officeHours) return; // If we don't have any office hours then there's nothing to do
        this.currentTimeMarker = AvailUtil.getDayFraction(
            S25Util.date.timezone.getDayProgress(this.userTimezone) / S25Const.ms.hour,
            this.officeHours,
        );
        this.changeDetector.detectChanges();
    }

    resetItemStyling(item: AvailItem) {
        item.gridData.style = {
            ...AvailUtil.getEventPositioning(item, this.officeHours),
            ...AvailUtil.getEventColoring(item, this.utilizationView, this.colorMap),
        } as AvailItem["gridData"]["style"];
        this.changeDetector.detectChanges();
    }

    updatePerms(perms: AvailGridPerms) {
        perms.location.forEach((perm, key) => this.perms.location.set(key, perm));
        perms.resource.forEach((perm, key) => this.perms.resource.set(key, perm));
        this.perms.locationKey = perms.locationKey;
        this.perms.resourceKey = perms.resourceKey;
    }

    @Bind
    hasMorePages() {
        return this.data.pages > this.currentPage + 1;
    }

    scrollToTop() {
        window.scroll(0, 0);
    }

    setMobileView() {
        this.isMobile = S25Util.mobileCheck() && window.innerWidth <= 430;

        const modeDropdown = this.elementRef.nativeElement.querySelector("s25-ng-opt-mode-dropdown");

        if (this.isMobile) {
            if (modeDropdown) this.renderer.addClass(modeDropdown, "ngHidden");
            this.onModeChange("overlapping"); // Mobile view currently only supports overlapping mode
        } else {
            if (modeDropdown?.classList.contains("ngHidden")) this.renderer.removeClass(modeDropdown, "ngHidden");
        }

        this.changeDetector.detectChanges();
    }
}

export type OfficeHours = { start: number; end: number };

export type AvailGridPerms = {
    location: Map<number, EventPerm>;
    resource: Map<number, EventPerm>;
    locationKey?: number;
    resourceKey?: number;
};

export type AvailMoveItem = {
    isKeyboard?: boolean;
    init: { x?: number; y?: number; itemLeft: number; itemTop: number };
    maxOffset: { top: number; right: number; bottom: number; left: number };
    item: AvailItem;
    new?: { start: number; end: number; eventStartTime: number; eventEndTime: number };
    currentTrack?: HTMLElement;
};

export type AvailResizeItem = {
    isKeyboard?: boolean;
    side: "left" | "right";
    init: { x?: number; itemLeft: number; itemWidth: number; eventWidth: number };
    item: AvailItem;
    min: number; // Earliest allowed time
    max: number; // Latest allowed time
    new?: { start: number; end: number; eventStartTime: number; eventEndTime: number };
    handle?: HTMLElement;
};

export type AvailPen = {
    row: AvailRow;
    track: AvailRow["tracks"][0];
    hours: number;
    style: { width: string; left: string };
    trackElement: HTMLElement;
    create?: { date: any; fixedTime: number; floatTime: number; init: number; min: number; max: number };
    timeLabels?: { start: string; end: string };
};
